<div class="terms-conditions">
  <div class="terms-conditions__header">
    <p class="terms-conditions__header__header-text heading1--extra-bold">الشروط و الأحكام</p>
    <p class="terms-conditions__header__subheader-text body1--medium">اخر تحديث في: يوليو 2024</p>
  </div>
  <div class="terms-conditions__brief">
    <p class="terms-conditions__brief__text body1--medium">
      توفر شركة تاجر مجموعة من الأدوات والموارد لإدارة المتاجر الإلكترونية، ومعالجة الطلبات وبيع
      المنتجات عبر الإنترنت وكذلك جميع الخدمات الإحترافية المتعلقة بذلك او الخدمات الأخرى ذات الصلة.
      و يشار الى جميع ما سبق بمصطلح "الخدمات".
    </p>
    <p class="terms-conditions__brief__text body1--medium">
      وفيما يلي البنود والشروط و الأحكام الخاصة باستخدام هذه الخدمات، إلى جانب أي تعديلات عليها وأي
      قواعد أو سياسات تشغيلية قد يتم نشرها من وقت لآخر بواسطة "تاجر".
    </p>
  </div>
  <div class="terms-conditions__details">
    <div class="terms-conditions__details__header">
      <img
        [src]="assetsPath + 'arrow.svg'"
        alt="arrow pointing at terms and conditions title"
        class="terms-conditions__details__header__icon"
      />
      <p class="terms-conditions__details__header__title heading1--extra-bold">اختر المحتوى</p>
    </div>
    <div class="terms-conditions__details__chips">
      <div
        *ngFor="let tac of termsAndConditions"
        (click)="toggleSelectedTAC(tac)"
        class="terms-conditions__details__chips--unselected body1--bold"
        [ngClass]="{ 'terms-conditions__details__chips--selected': tac.title === selectedTab }"
      >
        {{ tac.title }}
      </div>
    </div>
    <ol class="terms-conditions__details__text">
      <ng-container *ngFor="let content of displayedContent">
        <ul *ngIf="checkIsArray(content); else elseIfTemp">
          <li
            *ngFor="let contentItem of content"
            class="terms-conditions__details__text__subpoint body1--medium"
          >
            {{ contentItem }}
          </li>
        </ul>
        <ng-template #elseIfTemp>
          <li class="terms-conditions__details__text__point body1--medium">
            {{ content }}
          </li>
        </ng-template>
      </ng-container>
    </ol>
  </div>
</div>
