import { NgClass, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import { TERMS_AND_CONDITIONS } from 'src/app/presentation/shared/constants';
import { LandingEventsService } from '../../services/landing-events.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  standalone: true,
  imports: [NgFor, NgClass, NgIf],
})
export class TermsAndConditionsComponent implements AfterViewInit {
  assetsPath = 'assets/img/landing/faq/';

  termsAndConditions: { title: string; content: (string | string[])[] }[] = TERMS_AND_CONDITIONS;

  selectedTab: string = this.termsAndConditions[0].title;

  displayedContent: (string | string[])[] = this.termsAndConditions[0].content;

  constructor(private _landingEventsService: LandingEventsService) {}

  ngAfterViewInit(): void {
    document.body.scrollTop = 0;
  }

  checkIsArray(ele: any): boolean {
    return Array.isArray(ele);
  }

  toggleSelectedTAC(tac: { title: string; content: (string | string[])[] }): void {
    this.selectedTab = tac.title;
    this.displayedContent = tac.content;

    document.querySelectorAll('.terms-conditions__details__chips--unselected').forEach((ele) => {
      if (ele.textContent!.includes(this.selectedTab)) {
        ele.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
    });

    this._landingEventsService.sendLandingMixpanelEvent('terms_and_conditions_tab_change', {
      $selected_tab: this.selectedTab,
    });

    this._doLogGtagTermsAndConditionsTabChange(this.selectedTab);
  }

  private async _doLogGtagTermsAndConditionsTabChange(selectedTab: string) {
    this._landingEventsService.sendGtagMixpanelEvent('faq_questions_expanded', {
      'Selected Tab': selectedTab,
    });
  }
}
